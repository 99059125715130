import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-screen',
  templateUrl: './skeleton-screen.component.html',
  styleUrls: ['./skeleton-screen.component.scss'],
})
export class SkeletonScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
